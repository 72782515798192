import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2edc047a&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=2edc047a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@6.8.1_ejs@3.1.9_lodash@4.17.21_mustac_cdnoxvykq7d5hrgnqv6dmikmlm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2edc047a",
  null
  
)

export default component.exports